<template>
  <div class="chooseNationality-wrap">
    <div class="chooseNationality" @click="popupShowFn">
      <div class="ub ub-btw margin-top8 background-color-2 padding10 border-radius4">
        <div class="main-text-3 font14" v-show="!chooseNationalityObj.Nationality_type">{{ $t('profile.verifications.nationality') }}</div>
        <div class="main-text-1 font14 font-weight400" v-show="chooseNationalityObj.Nationality_type">
          {{ chooseNationalityObj.Nationality_type }}
        </div>
        <div>
          <img src="@/assets/images/walltes/arrow-right.svg" alt="">
        </div>
      </div>
    </div>
    <van-popup v-model="popupShow" position="bottom" :get-container="'.chooseNationality-wrap'">
      <div class="chooseNationality-list background-color-4">
        <van-nav-bar left-text=" " :border="false" >
          <p slot="left" class="font16 font-weight700">{{ $t('profile.verifications.nationality') }}</p>
          <img
            @click="onCancel"
            slot="right"
            src="@/assets/images/system/cross.svg"
            alt="close"
            title="close"
          />
        </van-nav-bar>
        <div class="withdraw-chooseNationality-main">
          <!-- <div class="padding-left20 padding-right20 padding-top20 main-text-3">
            {{ $t('chooseNationality.tip') }}
          </div> -->
          <ul class="list-box padding20">
            <li class="margin-top8 ub" v-for="(item,index) in filterchooseNationality" :key="index" @click="onConfirm(item)">
              <div class="icon-box margin-right12"></div>
              <p class="font14 main-text-1">
                {{ item.label }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Popup, Picker, Icon } from 'vant'
import Vue from 'vue'

Vue.use(Popup)
Vue.use(Picker)
Vue.use(Icon)

export default {
  name: 'ChooseNationality',
  props: {
    Nationality: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  watch: {
    Nationality: {
      handler: function () {
        this.chooseNationalityObj = this.Nationality
      },
      deep: true
    }
  },
  data () {
    return {
      value: '',
      columns: [
      ],
      chooseNationalityObj: {},
      popupShow: false
    }
  },
  computed: {
    filterchooseNationality () {
      let list = []
      if (this.value) {
        list = this.columns.filter((item) => {
          return item.Nationality_type.indexOf(this.value) !== -1
        })
      } else {
        list = this.columns
      }
      return list
    }
  },
  mounted () {
    this.fundNationalityListFn()
  },
  methods: {
    fundNationalityListFn () {
      this.columns = [{
        label: 'AAA'
      }, {
        label: 'BBB'
      }, {
        label: 'CCC'
      }]
      // fundNationalityList({}).then(res => {
      //   console.log(res, 'res=======')
      //   this.chooseNationalityObj = this.columns[0]
      //   this.$emit('callback', this.chooseNationalityObj)
      //   this.$toast.clear()
      // })
    },
    clearSearch () {
      this.value = ''
    },
    popupShowFn () {
      this.popupShow = true
    },
    onConfirm (value) {
      this.popupShow = false
      this.chooseNationalityObj = value
      this.$emit('callback', value)
    },
    onCancel () {
      this.popupShow = false
    }
  }
}
</script>
<style lang="less">
.chooseNationality-wrap{
  .chooseNationality{
    .icon-box{
      width: 80px;
      height: 40px;
    }
  }

.van-popup{
  background-color: rgba(0, 0, 0, 0);
}
.chooseNationality-list{
  width: 100%;
  height: 90vh;
  overflow: hidden;
  border-top-left-radius: 5vw;
  border-top-right-radius: 5vw;
  .withdraw-chooseNationality-main{
    height: 90vh;
    overflow: scroll;
    .list-box{
      box-sizing: border-box;
      overflow: scroll;
      li{
        padding-top: 24px;
        padding-bottom: 24px;
        .icon-box{
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #D9D9D9;
        }
      }
    }
  }
}
}
</style>
