<template>
  <div class="profile-verifyStep1-wrapper padding15">
    <div class="margin-top20">
      <p class="main-text-2 font14"> {{ $t('profile.verifications.nationality') }}</p>
      <div class="margin-top8">
        <Nationality></Nationality>
      </div>
    </div>
    <div class="margin-top20">
      <p class="main-text-2 font14"> {{ $t('profile.verifications.countryOfResidence') }}</p>
      <div class="margin-top8">
        <Nationality></Nationality>
      </div>
    </div>
    <div class="margin-top20">
      <p class="main-text-2 font14"> {{ $t('profile.verifications.firstName') }}</p>
      <div class="margin-top8">
        <div class="ub ub-btw margin-top8 background-color-2 padding10 border-radius4">
          <van-field type="number" rows="1" style="width: 80%;" :border="false" class="main-text-1 font14 font-weight500 break-word" v-model="fee"/>
          <div>
            <span class="main-text-3 margin-right8"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="margin-top20">
      <p class="main-text-2 font14"> {{ $t('profile.verifications.lastName') }}</p>
      <div class="margin-top8">
        <div class="ub ub-btw margin-top8 background-color-2 padding10 border-radius4">
          <van-field type="number" rows="1" style="width: 80%;" :border="false" class="main-text-1 font14 font-weight500 break-word" v-model="fee"/>
          <div>
            <span class="main-text-3 margin-right8"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="margin-top20">
      <p class="main-text-2 font14"> {{ $t('profile.verifications.IDNumber') }}</p>
      <div class="margin-top8">
        <div class="ub ub-btw margin-top8 background-color-2 padding10 border-radius4">
          <van-field type="number" rows="1" style="width: 80%;" :border="false" class="main-text-1 font14 font-weight500 break-word" v-model="fee"/>
          <div>
            <span class="main-text-3 margin-right8"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nationality from './nationality.vue'
export default {
  name: 'VerifyStep1',
  components: { Nationality },
  data () {
    return {
      verifyStep: 2
    }
  },
  created () {
  },
  methods: {
  }
}
</script>
<style lang="less">
.profile-verifyStep1-wrapper {
  box-sizing: border-box;
  .level-box{
    margin-top: 32px;
    padding: 24px 32px;
    border-radius: 16px;
    border: 1px solid #ECEAEF;
  }
  .van-cell{
    background-color: rgba(0,0,0,0);
    padding: 0;
  }
}
</style>
